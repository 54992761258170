<template>
   
    <div class="px-3 md:px-0 pt-4 pb-4">

        <div v-if="isFinish" class="px-4 flex min-h-screen items-center">
            <div class="text-center">
                <div class="w-24 mx-auto animate__animated animate__tada">
                    <img src="@/assets/images/check-mark.svg" class="w-full" />
                </div>
                <h2 class="font-semibold tracking-tight text-gray-800 text-3xl mt-3">
                    Konfirmasi Berhasil
                </h2> 
                <p class="text-gray-500  mb-5">Konfirmasi pembayaran anda berhasil dikirim. Saat ini sedang dilakukan proses verifikasi oleh admin.</p>
            </div>
        </div>
        <div v-else>
            <p class="text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize mb-6" v-if="paketUser != null">
                {{ paketUser.paket.nama_paket }}
            </p>

            <div class="" v-if="!isLoading && paketUser != null">
                <div class="text-center">
                    <div class="w-36 mx-auto">
                        <div class="h-36 w-36 rounded-lg content-center flex items-center text-center bg-yellow-200">
                            <img src="@/assets/images/credit-card.svg" class="w-24 h-24 mx-auto" />
                        </div>
                    </div>
                    <h2 class="font-semibold tracking-tight text-gray-800 text-3xl mt-3">
                        <span class="font-medium text-lg">Rp.</span> {{ paketUser.total_tagihan | moneyFormat }}
                    </h2> 
                    <p class="text-gray-500  mb-5">Total Tagihan</p>
                </div>
                <div class="form-target mt-3 border-2 rounded-lg px-3 py-2 bg-white">
                    <div class="font-bold text-gray-600 flex" @click="isExpandCaraBayar = !isExpandCaraBayar">
                        <span class="flex-grow">Cara Bayar</span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                    </div>
                    <transition name="fade">
                        <div class="konten-html transition-all duration-500" v-html="konten.cara_bayar" v-if="isExpandCaraBayar" />
                    </transition>
                </div>

                <div class="form-target mt-3 border-2 rounded-lg px-3 py-2 bg-white">
                    <p class="font-bold text-gray-600">Konfirmasi Pembayaran</p>
                    <div class="flex items-center my-4 mb-8">
                        <label class="w-2/5 hidden md:block">Nama Rekening</label>
                        <div class="flex-wrap w-full">
                            <custom-input id="atas_nama" label="Nama Pemilik Rekening" v-model="mainForm.nama_rekening" :errorMessage="errorForm.nama_rekening"/>
                        </div>
                    </div>
                    <div class="flex items-center my-4 mb-8">
                        <label class="w-2/5 hidden md:block">Nama Bank</label>
                        <div class="flex-wrap w-full">
                            <custom-input id="nama_bank" label="Nama Bank Rekening" v-model="mainForm.nama_bank" :errorMessage="errorForm.nama_bank"/>
                        </div>
                    </div>
                    <div class="flex items-center my-4">
                        <label class="w-2/5 hidden md:block">Bukti Transfer</label>
                        <div class="flex-wrap w-full">
                            <image-picker v-model="mainForm.fileBuktiTransfer" :placeholder="paketUser.bukti_bayar" />
                            <div class="text-xs text-red-700" v-if="errorForm.file_bukti_bayar != '' ">{{ errorForm.file_bukti_bayar }}</div>
                        </div>
                    </div>
                    <div class="flex items-center my-4">
                        <label class="w-2/5 hidden md:block"></label>
                        <div class="flex-wrap w-full">
                            <button @click="konfirmasiPembayaran()" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-yellow-200 text-yellow-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">
                                <svg v-if="loadingModalBayar" class="animate-spin ml-1 mr-3 h-5 w-5 text-yellow-700 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Konfirmasi Pembayaran
                            </button>

                            <div @click="modalDetailPaket = true" class="cursor-pointer block w-full text-center md:inline-block md:w-auto px-4 py-3 md:py-2 mt-2 bg-blue-200 text-blue-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">
                                Detail Paket
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <loading-modal :isShow="isLoading" />

        <modal :isOpen="modalDetailPaket" v-if="paket != null" >
            <div class="md:w-96">

                <div class="grid grid-cols-1">
                    <div>
                        <div class="bg-yellow-100 rounded-full w-40 h-40 mb-5 md:mb-0  md:w-40 md:h-40 flex items-center mx-auto">
                            <img src="@/assets/images/package.svg" class="w-1/2 mx-auto"/>
                        </div>
                    </div>
                    <div class="mt-2">
                        <h2 class="text-blue-700 font-semibold tracking-wider text-3xl">{{ paket.nama_paket }}</h2>
                        <div class="harga flex my-3" v-if="paket.status_akses == 1">
                            <span class="text-sm font-semibold">Rp.</span>
                            <div class="flex-grow">
                                <span v-if="paket.harga_coret != null" class="text-2xl font-bold tracking-wider text-red-500 mr-1"><s>{{ paket.harga_coret | moneyFormat }}</s></span>
                                <span class="text-3xl font-bold tracking-wider text-yellow-500">{{ paket.harga_paket | moneyFormat }}</span>
                            </div>
                        </div>
                        <p class="text-lg">{{ paket.deskripsi }}</p>
                    </div>
                </div>

                <div class=" flex items-center flex-row bg-white py-2 px-3 shadow rounded-lg my-2 transition-colors hover:border-gray-300"
                    v-for="(data, index) in paket.kuis" :key="index">
                    <div class="h-16 w-16 bg-blue-100 rounded-lg content-center flex items-center text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto text-blue-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </div>

                    <div class="flex-grow ml-3 pt-1 pb-1">
                        <h2 class="font-semibold tracking-tight text-gray-800 text-xl">{{ data.kuis.judul_kuis }}</h2>
                        <span class="mr-5 text-gray-700 text-based">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block -mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg> 
                            {{ data.soal_count }} Jumlah Soal
                        </span>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-2 mt-4">
                <button @click="modalDetailPaket = false" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1">Tutup</button>
                 <router-link :to="{
                        name : 'detailModul',
                        params : {
                            'singkatan_kategori': paketUser.paket.kategori.singkatan.toLowerCase(),
                            'tipe_modul'        : 'berbayar',
                            'id_paket'          : paketUser.id_paket
                        }
                    }" class="block w-full text-center md:inline-block md:w-auto px-4 py-3 md:py-2 bg-blue-200 text-blue-800 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1">Detail Paket</router-link>

            </div>
        </modal>

    </div>
</template>
<script>
import CustomInput from '../../components/CustomInput.vue';
import ImagePicker from '../../components/ImagePicker.vue';
import LoadingModal from '../../components/LoadingModal.vue';
import Modal from '../../components/Modal.vue';
import { errorHandler, extractValidationError, showErrorNotif } from '../../helpers/Tools';
export default {
    name : 'TagihanDetail',
    components: { LoadingModal, CustomInput, ImagePicker, Modal },
    data(){
        return {
            isFinish         : false,
            isLoading        : false,
            paketUser        : null,
            isExpandCaraBayar: true,
            modalDetailPaket : false,
            loadingModalBayar: false,
            paket            : null,

            mainForm : {
                sesi_ke          : "",
                nama_rekening    : '',
                nama_bank        : '',
                kode_event       : '',
                filePersyaratan  : [],
                fileBuktiTransfer: null,
                kode_promo       : '',
            },
            errorForm : {
                sesi_ke : "",
                file_persyaratan : "",

                nama_rekening   : '',
                nama_bank       : '',
                file_bukti_bayar: '',
                kode_promo      : '',
                kode_event      : ''
            }
        }
    },
    computed: {
        konten () {
            return this.$store.getters.getKonten
        }
    },
    mounted() {
        this.getTagihan();
    },
    methods : {
        async getTagihan() {
           this.isLoading = true;
            try {
                let res = await this.$store.dispatch("getDetailTagihanPaket", {
                    id_paket_user: this.$route.params.id_paket_user
                });
                this.isLoading = false;
                let result = res.data;
                if(result.status == 'success') {
                    this.paketUser = result.data;
                    this.paket = this.paketUser.paket;

                    this.mainForm.nama_rekening = this.paketUser.nama_rekening;
                    this.mainForm.nama_bank = this.paketUser.nama_bank;

                } else {
                    showErrorNotif(this, result.message);
                }
            } catch (error) {
                this.isLoading = false;
                errorHandler(this, error);
            }
        },

        async konfirmasiPembayaran() {
            this.loadingModalBayar = true;
            try {

                let formData = new FormData();
                formData.append('file_bukti_bayar', this.mainForm.fileBuktiTransfer);
                if(this.mainForm.nama_rekening != null && this.mainForm.nama_rekening != 'null') {
                    formData.append('nama_rekening', this.mainForm.nama_rekening);
                }

                if(this.mainForm.nama_bank != null && this.mainForm.nama_rekening != 'null') {
                    formData.append('nama_bank', this.mainForm.nama_bank);
                }

                let res = await this.$store.dispatch("konfirmasiBayarModul", {
                    id_paket : this.paketUser.id_paket,
                    formData : formData
                });
                this.loadingModalBayar = false;
                let result = res.data;
                if(result.status == 'success') {
                    this.isFinish = true;
                } else {
                    showErrorNotif(this, result.message);
                    this.errorForm = extractValidationError(this.errorForm, result.data);
                }
            } catch (error) {
                this.loadingModalBayar = false;
                errorHandler(this, error);
            }
        },
    }
}
</script>