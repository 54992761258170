<template>
<div>
    <div class="file-picker flex w-full flex-wrap">
        <div @click="targeClicked()" class="w-full h-40 p-3 md:h-52 m-1 rounded overflow-hidden file-picker border-dashed border-2 items-center flex flex-col text-gray-400 hover:text-gray-700 transition-all cursor-pointer hover:border-gray-400">
            <div v-if="value == null && placeholder == null" class="text-center m-auto">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                <span class="text-sm">Pilih gambar</span>
            </div>
            <div v-else class="h-full">
                <img :src="objectUrl(value)" class="h-full rounded mx-auto" />
            </div>
        </div>
        <input type="file" name="fileSelect" class="hidden" @change="onFileChange" accept="image/*" ref="fileSelect" />
    </div>
    <modal :isOpen="isOpenModalPreview">
        <img :src="objectUrl(value)" class="w-1/2 md:w-2/3 mx-auto rounded-lg mb-2" v-if="value != null || placeholder != null">
        <div class="flex space-x-1">
            <div class="flex-grow px-3 py-2 bg-blue-400 text-white rounded-lg mt-1 text-center cursor-pointer hover:bg-blue-500" @click="openFilePicker">Ganti Foto</div>
            <button @click="isOpenModalPreview = false" class="px-3 py-2 bg-gray-100 text-gray-800 rounded-lg mt-1 text-center cursor-pointer hover:bg-gray-200 flex-grow">Tutup</button>
        </div>
    </modal>
</div>
</template>
<script>
import Modal from './Modal.vue'
export default {
  components: { Modal },
    name: 'ImagePicker',
    data() {
        return {
            isOpenModalPreview: false,
        }
    },
    props: {
        value      : null,
        placeholder: null
    },
    methods: {
        targeClicked() {
            if(this.placeholder == null && this.value == null) {
                this.openFilePicker();
            } else {
                this.isOpenModalPreview = true;
            }
        },
        openFilePicker() {
            this.isOpenModalPreview = false;
            this.$refs.fileSelect.click();
        },
        objectUrl(file) {
            if(this.placeholder != null && file == null) {
                return this.placeholder
            }

            return URL.createObjectURL(file)
        },
        onFileChange (e) {
            this.$emit('input', e.target.files[0])
        },

        previewImageFile(url) {
            if(typeof window.flutter_inappwebview != 'undefined') {
                window.flutter_inappwebview.callHandler('instancy.imageViewer', url);
            } else {
                window.open(url, '_blank');
            }
        }
    }
}
</script>
